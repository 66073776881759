<template>
  <div 
    v-if="visible"
    :class="{...cssClasses}"
  >
    <fr-label 
      for-id="fr-programs"
      class=""
    >
      {{ label || field.meta.label }}
    </fr-label>
    <fr-select
      id="fr-programs"
      v-model="fieldValue"
      ref="selectField"
      :valid="!fieldHasErrors('fr-programs')"
      :required="field.admin.required"
      name="fr-programs"
      :default-option="defaultOption"
      :options="programOptions"
    />
  </div>
</template>

<script>
import FrLabel from '@/components/fields/base/FrLabel.vue'
import FrSelect from '@/components/fields/base/FrSelect.vue'
import { mapActions, mapGetters } from 'vuex'
import helpers from '@/helpers/helpers'
import validation from '@/helpers/validation'

export default {
  name: 'FrPrograms',

  components: {
    FrLabel,
    FrSelect
  },

  props: {
    /**
     * The form field that is being rendered.
     */
    field: {
      type: Object,
      required: true
    },

    /** 
     * The value that is passed from the parent component through `v-model`.
     */
    modelValue: {
      type: [Array, Object, String, Number, Boolean],
      default: undefined
    }
  },

  emits: ['update:modelValue'],

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      programs: 'getPrograms',
      submitObject: 'getSubmitObject',
      formFields: 'getFormFields',
      programsFilter: 'getProgramsFilter'
    }),


    /** 
     * The CSS classes to be applied to the element.
     * 
     * This is defined as a computed property so we can dynamically set classes.
     * 
     * @returns {Array}
     */
    cssClasses() {
      return {
        'fr-programs': true
      }
    },
    
    /**
     * The value that is passed from the parent component through `v-model`.
     * 
     * This is wrapped as a computed property so that it may be bound 
     * as a `v-model` to a child component. Setting this up as a proxy 
     * bypasses the `Avoid mutating a prop directly` error thrown by Vue.
     * Instead, we intercept this mutation and pass it along to the parent.
     * 
     * @param {Object} val
     * 
     * @returns {Object}
     */
    fieldValue: {
      get() { return this.modelValue },
      set(val) { this.$emit('update:modelValue', val) }
    },

    /**
     * The default option to be rendered by the programs dropdown.
     * 
     * @returns {Object}
     */
    defaultOption() {
      return {
        label: this.field.meta.placeholder ?? 'Please select a Program',
        value: '',
        selected: true,
        disabled: true
      }
    },

    /**
     * The programs to display on the form.
     * 
     * This will also filter the programs based on various criteria.
     * 
     * @returns {Array}
     */
    programOptions() {
      let programs = []
      let rawPrograms = this.programs
      let filter = this.programsFilter
      let groups = this.field.meta.groups

      let order = this.field.meta.sortType ?? 'asc';
     
    /*functions for add alphabatical sort for programs and program groups shows in programs dropdown*/
      function compare(a,b){
       
        if(order === 'asc'){
          if(a.label < b.label)
            return -1;
          if(a.label > b.label)
            return  1;
        return 0;
        }
      if(order === 'desc'){
          if(a.label < b.label)
            return 1;
          if(a.label > b.label)
            return  -1;
        return 0;
        }
       
      }

      /**
       * Filter all of the programs down to only the ones that match the filter.
       */
      if (Object.prototype.hasOwnProperty.call(filter, 'value') && filter.value) {
        // We need to look a level deeper and sort specifically for groups.
        if (filter.field === 'group') {
          rawPrograms = rawPrograms.filter(program => {
            return program.groups.data.find(group => group.id === filter.value)
          })
       
        } else {
          rawPrograms = rawPrograms.filter(program => {
            return program[filter.field] === filter.value
          })
       
        }
      }

      /** 
       * This is a bit ugly, but this will compile an array of options grouped by the specified criteria.
       * 
       * If there is no criteria to group by, we simply return a flat array of programs to render.
       */
      if (groups.length > 0) {
        groups.forEach(group => {
          // Find the group's option if it already exists.
          let groupIndex = programs.findIndex(item => {
            return (item.label === group.label) && (Object.prototype.hasOwnProperty.call(item, 'items'))
          })

          // Initialize the group's option so that we may attach the programs to the group.
          if (groupIndex === -1) {
            groupIndex = programs.push({
              label: group.label,
              items: []
            })

            groupIndex--
          }

          /**
           * Program groups need to be checked explicitly since they are nested into a relation object.
           * 
           * After program groups are checked, we can cross reference the value of the program field 
           * indicated on the filter object. If any of these return programs, we want to assign them
           * to the proper option as items so that they ay be rendered under the correct optgroup.
           */
          if (group.field === 'group') {

            programs[groupIndex].items.push(
              ...rawPrograms.filter(program => {
              
                return program.groups.data.find(item => item.id === group.value)
              }).map(program => {
                return {
                  label: program.display_name ?? program.name,
                  value: program.id,
                  selected: false,
                  disabled: false
                }
              })
            )

            programs[groupIndex].items.sort(compare);

          } else {
          
            programs[groupIndex].items.push(
              ...rawPrograms.filter(program => {
                return program[group.field] === group.value
              }).map(program => {
                return {
                  label: program.display_name ?? program.name,
                  value: program.id,
                  selected: false,
                  disabled: false
                }
              })
            )

            programs[groupIndex].items.sort(compare);
          }

        })      
      } else {
        programs.push(
          ...rawPrograms.map(program => {
            return {
              label: program.display_name ?? program.name,
              value: program.id,
              selected: false,
              disabled: false
            }
          })
        )

        programs.sort(compare);

      }

      return [ 
        ...programs
      ]  
    },

    /**
     * For dynamic display of fields
     * @returns {Boolean}
     */
    visible() {
      // if there is no visibility property, show the field
      // if the visibility array is empty or not set, show the field
      // if the visibilty array has an object in it, and the toggle is show, and the match condition is and, only return true if all conditions match
      // if the visibilty array has an object in it, and the toggle is show, and the match condition is or, only return false if no conditions match
      // if the visibilty array has an object in it, and the toggle is hide, and the match condition is and, only return false if all conditions match 
      if(!this.field.meta.visibility) {
        return true
      }

      if(this.field.meta.visibility.length === 0) {
        return true
      }
      if(this.field.meta.visibility.length > 0 && this.field.meta.visibility[0].toggle === "show") {
        return helpers.conditionalLogicEvaluation('visibility', this.formFields, this.field.meta, this.submitObject, this.programsFilter)        
      }
      if(this.field.meta.visibility.length > 0 && this.field.meta.visibility[0].toggle === "hide") {
        return !(helpers.conditionalLogicEvaluation('visibility', this.formFields, this.field.meta, this.submitObject, this.programsFilter))        
 
      }
      //just return true as a default emergency escape case
      return true
    },
    
    /**
     * For dynamic label settings
     * @returns {String}
     */
    label() {
      //if no attribute, return null for the default form config val to display
       if(!this.field.meta.display) {
        return null
      }

      if(this.field.meta.display.length === 0) {
        return null
      }
      //if length, if display name is not null or an empty string, else default
      if(this.field.meta.display.length > 0 && this.field.meta.display[0].displayName && this.field.meta.display[0].displayName !== '')  {
        //if conditional logic evaluates to true
        if(helpers.conditionalLogicEvaluation('display', this.formFields, this.field.meta, this.submitObject, this.programsFilter)) {
          return this.field.meta.display[0].displayName
        }
        return null      
      }

      return null

    }   
  },

  watch: {
        /**
     * Sets the validation to ignore if the field is not visible
     * @param {*} newVal 
     */
    visible(newVal) { 
      let steps = validation.setIgnoreValidation(this.field.id, !newVal)
      const { schema, labelLut } = validation.generateStepsSchema(steps)
      this.setSchemaObjectValue({key: "schema", value: schema})  
    },
    /**
     * Reset the program field if the filter is updated.
     */
    programsFilter() {
      this.fieldValue = ''
    },

    /**
     * Update the leadBuyerID if the page is marked as an affiliate.
     */
    fieldValue(newVal) {
      if ('affiliate' in this.submitObject && this.submitObject.affiliate) {
        let program = this.programs.find(prog => prog.id === newVal)

        this.updateSubmitObject({
          key: 'leadBuyerID',
          value: program.account.data.lead_buyer_id ?? null
        })
      }
    }
  },


  created() {
    let steps = validation.setIgnoreValidation(this.field.id, !this.visible)
    const { schema, labelLut } = validation.generateStepsSchema(steps)
    this.setSchemaObjectValue({key: "schema", value: schema})  
  },

  methods: {
    /** 
     * The actions mapped from Vuex.
     */
    ...mapActions({
      updateSubmitObject: 'updateSubmitObject',
      setSchemaObjectValue: 'setSchemaObjectValue'
    }),

    fieldHasErrors(name){
      return validation.fieldHasErrors(name)
    },

    focus() {
      this.$refs.selectField.focus();
    }
  }
}
</script>

<style lang="scss" scoped>
// Styles go hurr.
</style>
